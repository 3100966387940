

var ARROW_H = 10;
var ANIM_TIME = 0.2;
var GLOBAL_PROMPT_SELECTOR = '#global-prompt';

var gsap = require('greensock');
var Promise = require('Promise');
var TweenMax = gsap.TweenMax;
var ease = gsap.Expo;

function disappearGlobalPrompt(container) {
	var h = container.height();
	return new Promise(function(resolve){
		TweenMax.fromTo(container, ANIM_TIME, {
			top:0,
			ease:ease.easeIn
		}, {
			top : h * -1,
			onComplete: function(){
				container.empty();
				resolve();
			}
		});
	});
};

module.exports = {

	//montre une erreur pour une element
	showElementError: function(el, msg){

		var promptContent = $('<div class="element-error-content">').html(msg);
		var prompt = $('<div class="element-error">').append(promptContent);
		el.before(prompt);
		var elPos = el.position();
		var elW = el.width();
		var promptW = promptContent.outerWidth();
		var promptH = promptContent.outerHeight();
		prompt.css({
			top: elPos.top+'px',
			left: (elPos.left + elW - (promptW / 2))+'px',
			marginTop: (-1 * (promptH + ARROW_H))+'px'
		});

		function removePrompt(){
			// console.log('remove', el, msg);
			prompt.remove();
			el.off('.prompt');
		}

		if(el.is(':input')) {
			el.on('focus.prompt', removePrompt);
		}

		prompt.on('click.prompt', removePrompt);

	},

	appendGlobalFromAjax: function(res){
		var loadedPrompts = $(GLOBAL_PROMPT_SELECTOR, res);
		//si le node n'est pas trouvé avec le selector, il est possible que le node soit au premier niveau du jquery donné
		if(loadedPrompts.length == 0){
			loadedPrompts = $(res).filter(GLOBAL_PROMPT_SELECTOR);
		}

		//select dans la page, et append to si existe, insert si non
		var container = $(GLOBAL_PROMPT_SELECTOR);
		var onDisappear;
		if(container.length){
			onDisappear = disappearGlobalPrompt(container);
		} else {
			loadedPrompts.appendTo('body');
			onDisappear = Promise.resolve();
		}
		
		onDisappear.then(function(){
			container.html(loadedPrompts.html());
			var h = container.height();
			TweenMax.fromTo(container, ANIM_TIME, {top:h * -1, ease:ease.easeOut}, {top:0});
			this.setupGlobalPrompts();
		}.bind(this));
	},

	setupGlobalPrompts: function(){

		var container = $(GLOBAL_PROMPT_SELECTOR);

		var globalMsgs = $('.global-msg');
		globalMsgs.each(function(i, el){
			var msg = $(el);
			var close = $('.close', msg);
			close.off('.err').on('click.err', function(){
				disappearGlobalPrompt(container);
			});
		});

	}

};