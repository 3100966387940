'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';

import Home from './Home';
import Blog from './Blog';

var Menu = require('./Menu');
var Contact = require('./Contact');
var ImportSVG = require('./ImportSVG.js');
var skrollr = require('../vendor/skrollr');
var Anchor = require('./Anchor');

require('../vendor/jquery.selectric');
require('../vendor/slick.min');

ns.docReady.then(() => {
	document.documentElement.className += (("ontouchstart" in document.documentElement) ? ' touch' : ' no-touch');

	$('[class^=button]').on('click.tobo', (e) => { $(e.currentTarget).blur(); })

	$('html').addClass(navigator.userAgent.match(/Edge\/\d+/) ? 'edge' : '');

	ImportSVG();

	Menu.init();
	Blog.init();
	Anchor.init();

	setTimeout(()=>{
		$(window).scrollTop(0);
	}, 100);

	if(!ns.isMobile(1024)){
		let s = skrollr.init({
			forceHeight: false
		});
	}

	if(navigator.userAgent.match(/Trident\/7\./)) {
		$('body').on("mousewheel", function (event) {

			event.preventDefault();
			var wd = event.wheelDelta;
			var csp = window.pageYOffset;
			window.scrollTo(0, csp - wd);
		});

	}

	if(navigator.userAgent.match(/Edge\/12\./)) {
		$('body').on("mousewheel", function (event) {

			event.preventDefault();
			var wd = event.wheelDelta;
			var csp = window.pageYOffset;
			window.scrollTo(0, csp - wd);
		});
	}

	$('.blog-slider').slick({
		slide:'.slide',
		dots: true,
		arrows: false,
		autoplay: true
	});

	$('.header-slider').slick({
		slide:'.header-slide',
		dots: true,
		arrows: false,
		autoplay: true
	});

	$(window).on('resize.tobo_footer', function(){
		let footerH = ns.isMobile(1024) ? 0 : $('footer').outerHeight();
		$('body').css({paddingBottom: footerH});
	}).resize();

	let iso = $('.masonry-container').isotope({
		itemSelector: '.masonry-item',
		layoutMode: 'fitRows',
		fitRows:{
			gutter: 30
		}
	});

	let activeFilters = {};
	$('.filter select').selectric({
		disableOnMobile:false,
		onChange:function(el){
			let filter = $(el).data('filter');
			let value = $(el).val();
			let filterString = '';
			let masonryItems = $('.masonry-item');
			let possibleItems = masonryItems;
			possibleItems.removeClass('filter-approved');

			activeFilters[filter] = value;

			for(let _filter in activeFilters) {
				if(activeFilters.hasOwnProperty(_filter)) {
					let val = activeFilters[_filter];

					if(val > -1) {
						possibleItems.each((i, el)=>{
							let $el = $(el);
							let data = $el.data(`${_filter}`).toString();
							let dataArr = data.split(',');

							if(dataArr.indexOf(val) === -1) {
								possibleItems = possibleItems.not($el);
							}
						});
					}
				}
			}

			possibleItems.addClass('filter-approved');
			iso.isotope({filter: possibleItems});
		}
	});

	let expandBtn = $('.expand-btn');
	expandBtn.on('click.tobo_expand', (e)=>{
		e.preventDefault();

		let clicked = $(e.currentTarget);
		clicked.toggleClass('opened');
		$(clicked.data('expand')).slideToggle();
	});

	if($('body').hasClass('home')){
		let homePage = new Home().init();
	}

		/*$('.selectric-item').on('scroll.tobo', (e)=>{
			//console.log(e);
			e.stopPropagation();
		});/**/
	// }

	// if($('body').hasClass('about')){
	//     let stepScroller = new StepScroller({
	//         sections: $('.team-member')
	//     }).init();
	// }
});
