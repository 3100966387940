'format es6';
'use strict';

class Utils {
	static throttle(fn, threshhold, scope) {
		threshhold || (threshhold = 250);
		var last;
		return function () {
			var context = scope || this;

			var now = +new Date;
			var args = arguments;
			if (last && now < last + threshhold) {
				// do nothing
			} else {
				last = now;
				fn.apply(context, args);
			}
		};
	}

	static sign(num) {
		return (num < 0) ? -1 : (num > 0) ? 1 : 0;
	}
}

export default Utils;
