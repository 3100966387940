

var Prompts = require('./Prompts');

function validateEmail(val) {
	// console.log(val);
	return val.match(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);
}

function validateFields(totalValid, inp) {
	inp = $(inp);
	var rule = inp.data('validate');
	var val = inp.val();
	var type = inp.prop('type');
	if(type==='email'){
		rule = 'email';
	}
	// console.log(rule);
	var isValid ;
	switch(rule) {
		case 'email':
			isValid = !!val && !!validateEmail(val);
			break;
		default:
			// console.log('def');
			isValid = !!val;
	}

	if(!isValid) {
		// console.log('prompt', inp.data('validate-msg'));
		Prompts.showElementError(inp, inp.data('validate-msg') || 'error');
	}

	// console.log(totalValid, isValid, val);
	totalValid &= isValid;

	return totalValid;
}


module.exports = {

	checkForm: function(form) {

		var validating = $('[data-validate]', form).toArray();

		return validating.reduce(validateFields, true);

	}

}