'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
// import StepScroller from './StepScroller';
import gsap from 'greensock';

function Home(){
    let $window = $(window);
    let menu = $('.menu-head');
    let desktopMenuHeight = ns.desktopMenuHeight;
    let fixMenuTreshold = $('.newsletter-block').offset().top - (desktopMenuHeight * 1.5);

    let realisationsContainer = $('.home-realisations');
    let realisations = realisationsContainer.find('.realisation');
    let interval;
    let tween = null;
    let scroll = {
        y : $window.scrollTop()
    };
    let lastScrollValue = $window.scrollTop();
    let sameScrolltopCounter = 0;
    let menuIsFixed = false;


    let unfixMenu = () => {
        menu.addClass('transparent');
        menuIsFixed = false;
    };

    let fixMenu = () => {
        menuIsFixed = true;
        menu.removeClass('transparent');
		gsap.TweenMax.from(menu, 0.6, { y: -desktopMenuHeight, ease: gsap.Expo.easeOut });
    };

    let killTween = () => {
        tween.kill();
        tween = null;
    }


    let intervalFunc = () => {
        if($window.scrollTop() === lastScrollValue) {
            sameScrolltopCounter++;
        }
        else {
            sameScrolltopCounter = 0;
        }

        if(sameScrolltopCounter >= 3) {
            let closestRealisationPos = 99999;
            let closestRealisationIndex = 0;
            realisations.each(function(i, el){
                if(Math.abs(scroll.y - $(el).offset().top) < closestRealisationPos) {
                    closestRealisationPos = Math.abs(scroll.y - $(el).offset().top);
                    closestRealisationIndex = i;
                }
            });

            animateScroll(closestRealisationIndex);
        }
        else {
            if(tween) {
                killTween();
            }
        }

        lastScrollValue = $window.scrollTop();
    }

    let animateScroll = (closestRealisationIndex) => {
        let targetPos = realisations.eq(closestRealisationIndex).offset().top;

        if(targetPos != scroll.y){
            tween = new gsap.TweenMax(scroll, 0.6, {
                y: targetPos,
                ease:gsap.Expo.easeInOut,
                delay:0,
                onUpdate:()=>{
                    $window.scrollTop(scroll.y);
                },
                onComplete:()=>{
                    killTween();
                }
            });
        }
    };

    let handleFixedImages = (scrolltop) => {
        scroll.y = scrolltop;
        clearInterval(interval);

        if(tween) {
            return;
        }

        if(scrolltop >= realisationsContainer.offset().top){
            if(scrolltop + $window.height() > realisations.last().offset().top + $window.height()){
                realisationsContainer
                    .removeClass('fixed')
                    .addClass('bottom');
            }
            else {
                interval = setInterval(intervalFunc, 100);

                realisationsContainer
                    .removeClass('bottom')
                    .addClass('fixed');
            }
        }
        else {
            $('.home-realisations').removeClass('fixed');
        }
    };

    let scrollHandler = (e) => {
		if ($window.width() > 1024) {
			let scrolltop = $window.scrollTop();
			handleFixedImages(scrolltop);

			if(scrolltop >= fixMenuTreshold && menu.css('position') != 'fixed') {
				fixMenu();
			}
			else if (scrolltop === 0) {
				unfixMenu();
			}
		}
    };

    let init = () => {
        $window.on('resize.tobo_home', ()=>{
            if($window.width() < 1024) {
                $window.off('scroll.tobo_home');
                unfixMenu();
            }
            else {
                $window
                    .off('scroll.tobo_home')
                    .on('scroll.tobo_home', scrollHandler)
                    .trigger('scroll.tobo_home');

                $window
                    .off('wheel.tobo_home')
                    .on('wheel.tobo_home', ()=>{
                        clearInterval(interval);
                        if(tween) {
                            killTween();
                        }
                    });
            }
        }).trigger('resize.tobo_home');

        $window.on('resize.tobo_home', (e)=>{
            if(ns.isMobile()){
                $('[class^=menu-head]').removeClass('transparent');
            }
            else {
                if(!menuIsFixed){
                    $('[class^=menu-head]').addClass('transparent');
                }
            }
        }).resize();
    };

    return {
        init: init
    }
};


export default Home;
