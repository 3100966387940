'format es6';
'use strict';


import $ from 'jquery';
import ns from 'ns';

//prototype des formulaires ajax dont le output est du html
var Prompts = require('./utils/Prompts');
var Validate = require('./utils/Validate');

var FORM_SELECTOR = '#write-us-form';
var RES_SELECTOR = '#write-us-result';


var onSubmitContact = (function(){
	
	var submitted = false;
	var gs = require('greensock');
	var msgContainer  ;

	function onSendError(response){
		gs.TweenMax.killTweensOf(form);
		gs.TweenMax.to(form, 0.2, {opacity:1});

		submitted = false;
		showResult(response, 'error');
	}

	function showResult(response, classSuffix){
		var msg = $('<h2 class="alert alert-'+classSuffix+'">');
		msg.prepend(response).appendTo(msgContainer);
		msgContainer.show().fadeIn(1000, function(){
			gs.TweenMax.to($(window), 400, {scrollTo : RES_SELECTOR,  ease : gs.Sine.easeInOut});
		});
	}
	
	return function(e) {
		e.preventDefault();
		if(submitted) return;
		var form = $(e.currentTarget);
		
		submitted = true;
		var valid = Validate.checkForm(form);
		// console.log(valid);
		// return;
		if(valid){

			var data = form.serialize();
			
			msgContainer = msgContainer || $(RES_SELECTOR);
			msgContainer.empty();
				
			gs.TweenMax.killTweensOf(form);
			gs.TweenMax.to(form, 0.2, {opacity:0.5});
			$.ajax({
				type :'POST',
				url: form.prop('action'),
				data : data,
				dataType : 'json',
				success: function(res) {
					//console.dir(res);
					if(res.error) {						
						onSendError();
						return;
					}

					form.css('overflow','hidden');
					gs.TweenMax.to(form, 0.6, {height:0});

					showResult(res.response, 'success');
					
				},
				error: function(res, txStatus, err){
					console.log(err);
					console.log(res.responseText);
					onSendError('error while posting message');
				}
				
			});
		} else {
			submitted = false;
		}
		
	};
})();



ns.docReady.then(function(){
	$(FORM_SELECTOR).on('submit.mdg', onSubmitContact);
});
