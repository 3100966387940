'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import Utils from './Utils.js';
import gsap from 'greensock';


const blogSelector = '#blog';
let blogNode;
let filters;


function doRequest(){

	// console.log(filters);
	let url = ns.blog.listurl.replace(/http:\/\/[^\/]+/, '');
	// console.log(url);
	const data = {
		url: url,
        crossDomain: false,
		xhrFields: {
			withCredentials: false
		},
		data: {
			blog:{
				filter:filters
			},
		}
	};
	// console.log(data);
	let req = $.ajax(data);

	function onRes(res) {
		let html = $(res);
		let loaded = $(blogSelector, html);
		// console.log(blog.html());
		blogNode.empty().html(loaded.html());
		highlightTags();
		setupTags(blogNode);
	}

	req.then(
		onRes,
		(jqxhr) => {
			// console.log(jqxhr.responseText);
			let res = $(jqxhr.responseText);
			onRes(res);
		}
	);



}

function toggleTag(tag){
	// console.log(tag);
	if(!tag){
		filters.length = 0;
	} else {
		let idx = filters.indexOf(tag);
		//si filter a le tag, l'enleve, sinon l'add
		if(idx>-1){
			filters.splice(idx, 1);
		} else {
			filters.push(tag);
		}
	}
	doRequest();
};

function setupTags(container) {
	let tags = $('[data-blog-tag]', container);
	// console.log(tags.length);
	tags.on('click.tag', (e) => {
		e.preventDefault();
		let clicked = $(e.currentTarget);
		let tag = clicked.data('blog-tag');
		toggleTag(tag);
	});
}

function highlightTags(){
	// return;
	let allTags = $('[data-blog-tag]');
	let allCategories = $('[data-blog-category]');

	let isFilteredByCategory = filters.reduce((carry, tag) => {
		return carry || !!(allCategories.filter('[data-blog-tag="'+tag+'"]').length);
	}, false);


	allTags.each((i, el) => {
		let node = $(el);
		// console.log(node.data('blog-tag'));
		let tagValue = node.data('blog-tag');
		let isFilteredBy = filters.indexOf(tagValue)>-1;
		//category "all" must be highlighted if not filtered by category
		let isAllCategories = tagValue===0 && !isFilteredByCategory;
		let fcn = (isFilteredBy || isAllCategories) ? 'addClass' : 'removeClass';
		node[fcn]('active');
	});
}



export default {

	init : () => {
		blogNode = $(blogSelector);

		let hasBlog = !!blogNode.length;
		if(!hasBlog) return false;

		filters = (ns.blog && ns.blog.filter) || [];

		setupTags();
		highlightTags();

	}
};
