"use strict";

var init = function(){
	let isAnchor = document.querySelector('[data-anchor-check]');
	if(isAnchor) {
		let url = window.location;
		let anchor = '#' + url.href.split('#')[1];

		let destination = document.getElementById(anchor);
		let dest_pos;

		if (destination) dest_pos = destination.getBoundingClientRect();

		if( anchor && dest_pos ) {
			setTimeout(()=>{
					window.scrollTo(0, dest_pos.top - 100);
			}, 200);
		}
	}
};

module.exports = {
	init: init
}
