"use strict";
var $ = require('jquery');

function ImportSVG () {

	var svgList = {};
	$('.import-svg').each(function(i, el){
		el = $(el);
		if(svgList[el.data('inline')]) {
			svgList[el.data('inline')].push(el);
		} else {
			svgList[el.data('inline')] = [el];
		}
	});

	for(var svg in svgList) {
		(function(svgPath){
			$.ajax({
				cache: true,
				url: svgPath,
				complete: function(data){
					svgList[svgPath].forEach(function(el){
						var s = $(data.responseText);
						s.attr('data-inline', svgPath);
						el.removeClass('import-svg');
						var _class = el[0].className;
						s.attr('class', _class);
						el.replaceWith(s);
					});
				}
			});
		})(svg);
	}
}

module.exports = ImportSVG;
